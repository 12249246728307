.snackbar {
  position: fixed;
  top: 1rem;
  right: 1rem;
  max-width: calc(100% - 2rem);
  width: 100%;
  z-index: 9000;

  @include SmallTablet {
    top: 1.5rem;
    max-width: 40rem;
  }
  @include Laptop {
    top: 10rem;
  }

  &__right {

    .snacks-item {
      animation-name: show-right;

      &__closing {
        animation-name: hide-right;
      }
    }
  }

  &__left {

    .snacks-item {
      animation-name: show-left;

      &__closing {
        animation-name: hide-left;
      }
    }
  }
}

.snacks {

  &-item {
    display: flex;
    align-items: center;
    animation-duration: .3s;
    padding: .8rem;
    border-radius: 1rem;
    color: $white;

    &__warning {
      background-color: $warn;
    }

    &__success {
      background-color: $green-light;
      color: $success;

      svg {
        & path {
          fill: $success;
        }
      }
    }

    &__error {
      background-color: $error;
    }

    &__info {
      background-color: $info;
    }
  }

  &-message {
    font: {
      size: 1.4rem;
      family: $ff-sb;
    };
    flex-grow: 1;
  }

  &-icon {
    &__close {
      cursor: pointer;
    }

    &__wrapper {
      display: flex;
      align-self: flex-start;
      padding: 5px;
      width: 30px;
      height: 30px;
    }
  }

  @keyframes show-right {
    from {
      opacity: 0;
      transform: translateX(25rem);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes hide-right {
    from {
      opacity: 1;
      transform: translateX(0);
    }
    to {
      opacity: 0;
      transform: translateX(25rem);
    }
  }

  @keyframes show-left {
    from {
      opacity: 0;
      transform: translateX(-25rem);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes hide-left {
    from {
      opacity: 1;
      transform: translateX(0);
    }
    to {
      opacity: 0;
      transform: translateX(-25rem);
    }
  }
}