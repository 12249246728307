@import '../../../node_modules/react-datepicker/dist/react-datepicker.css';
@import 'reset';
@import 'variables';
@import 'mixins';
@import 'typography';
@import 'grid';
@import 'animations';
@import 'helpers';
@import 'formElements';
@import 'ui';

@font-face {
  font-family: 'ProximaNovaBold';
  src:
    url('../fonts/ProximaNova-Bold.woff2') format('woff2'),
    url('../fonts/ProximaNova-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNovaSemibold';
  src:
    url('../fonts/ProximaNova-Semibold.woff2') format('woff2'),
    url('../fonts/ProximaNova-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNovaRegular';
  src:
    url('../fonts/ProximaNova-Regular.woff2') format('woff2'),
    url('../fonts/ProximaNova-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

html {
  min-height: 100vh;
  font: {
    size: 10px;
    family: $ff-base;
    weight: $fw-r;
  }

  body {
    min-height: 100vh;
    background-color: $blue-light;
    color: $black;

    @include flexify($column: true);

    #root {
      flex-grow: 1;
      min-height: 100vh;
      overflow: hidden !important;

      @include flexify($column: true, $align: center);
    }
  }

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

* {
  font: {
    family: $ff-base;
    weight: $fw-r;
  }
}

b,
strong {
  font: {
    family: $ff-b;
    weight: $fw-b;
  }
}

.loader-wrap {
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  padding: 1rem;
}

main.container {
  gap: 2rem;
  flex-grow: 0;
  margin: {
    top: 1rem;
    bottom: 0;
  }
  @include flexify($column: true);

  @include Tablet {
    margin: {
      top: 4rem;
      bottom: 4rem;
    }
    flex-grow: 1;
  }
}
