.promo-timer {
  @include flexify($column: true);
  border-radius: 1rem;
  margin-bottom: 2rem;
  padding: 1rem;

  & .time {
    @include flexify($align: center, $justify: center);
    margin-top: 1rem;
    padding: .8rem;
    border-radius: .5rem;
  }

  &.success {
    background-color: $green-alert-border;
    color: $green;

    & .time {
      background-color: $green-light;
    }
  }

  &.warning {
    background-color: $yellow-alert-border;
    color: $yellow-alert-text;

    & .time {
      background-color: $yellow-alert-bg;
    }
  }

  &.error {
    background-color: $red-alert-border;
    color: $red-dark;

    & .time {
      background-color: $red-light;
    }
  }
}