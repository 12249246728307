.btn {
  position: relative;
  padding: 1.35rem;
  text-align: center;
  min-width: 22rem;
  cursor: pointer;
  transition: all 0.3s ease;
  max-height: 5rem;
  border-radius: 10rem;
  text-decoration: none;
  @include flexify($align: center, $justify: center);

  &.small {
    max-width: 16rem !important;
    min-width: 16rem;
    padding: 0.7rem;
    & .btn-text {
      font-size: 1.2rem;
      line-height: 1.8rem;
    }
  }

  span {
    z-index: 2;
  }

  &-switch-group {
    gap: 1rem;
    margin-bottom: 0.5rem;
    @include flexify($justify: center, $wrap: wrap);

    > button[class*='pill-'] {
      flex-basis: calc((100% / 3) - 1.5rem);
      border-radius: 10rem;
      cursor: pointer;
      transition: all 0.3s ease;

      &:hover {
        border-color: $blue;
        outline: 1px solid $blue;
      }
    }
  }


  .btn-icon {
    @include flexify($align: center, $justify: center);
  }

  .btn-text {
    line-height: 1.8rem;
    font: {
      family: $ff-sb;
      weight: $fw-sb;
      size: 1.4rem;
    }
  }

  &-sm {
    min-width: auto;
    padding: 0.5rem;
  }

  &:disabled {
    pointer-events: none;
  }

  &--primary {
    background: linear-gradient(185deg, rgba(137, 177, 237, 1) 0%, rgba(42, 70, 147, 1) 70%);
    color: $white !important;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 10rem;
      background: $blue-hover;
      opacity: 0;
      z-index: 1;
      transition: 0.3s ease;
    }

    &:hover::after,
    &:focus::after {
      opacity: 1;
    }

    &:disabled,
    &.disabled {
      cursor: default;
      background: {
        image: none;
        color: $grey-dis;
      }

      span {
        color: $grey-txt;
      }
    }

    &:disabled {
      &::after {
        content: none;
      }
    }
  }

  &--bar {
    min-width: auto;
    width: 2rem;
    height: 2rem;
    padding: 0;

    @include Laptop {
      display: none;
    }
  }

  &-secondary {
    @include flexify($align: center, $justify: center);
    gap: 0.3rem;
    padding: 0.8rem 1.2rem;
    border-radius: 1rem;
    border: none;
    color: $black;
    font: {
      family: $ff-sb;
      size: 1.2rem;
      weight: $fw-sb;
    }
    background: {
      color: $grey-main;
    }
    cursor: pointer;
    transition: background-color .3s ease, color .3s ease;

    &.without-icon {
      padding: 0.8rem 1.2rem;
    }

    span {
      font: {
        family: $ff-sb;
        size: 1.2rem;
        weight: $fw-sb;
      }
    }

    &.dark {
      background-color: $blue-alert-bg;
    }

    @include Laptop {
      &:not(.dark):hover,
      &:not(.dark):active {
        background: {
          color: $grey-hover;
        }
      }

      &.dark:hover,
      &.dark:active {
        background: {
          color: $primary;
        }
        color: $white;
      }
    }

    & .arrow {
      width: 1rem;
      height: 2rem;

      svg {
        margin: 0;
        path {
          stroke: $black;
        }
      }
    }

    &.up {
      background-color: $blue-alert-bg;
      color: $blue;
      .arrow {
        transform: scale(-1);
      }
    }
  }

  &-tertiary {
    border-radius: 1rem;
    padding: 1.1rem 1.5rem;
    background-color: $blue-light;
    color: $blue;
    transition:
      background-color 0.3s ease,
      color 0.3s ease,
      border 0.3s ease;

    &.current:not([class*='with-border']) {
      color: $white;
      background-color: $blue;
      pointer-events: none;
    }

    &:not([class*='with-border']):hover {
      color: $white;
      background-color: $blue;

      & span {
        color: $white;
      }

      & svg {
        & path {
          stroke: $white;
        }
      }
    }
    &.with-border {
      border: 1px solid transparent;

      &.current {
        color: $black;
        border: 1px solid $blue;
      }

      &:hover {
        border: 1px solid $blue;
      }
    }

    &.dark {
      color: $white;
      background-color: $blue;
      padding: 0.6rem 0.9rem;
      cursor: pointer;

      &:hover {
        color: $blue;
        background-color: $blue-light;

        & span {
          color: $blue;
        }
      }
    }
  }

  &-user {
    @extend .btn-secondary;
    color: $black;
    background-color: $blue-alert-bg;
    border-radius: 10rem;
    max-width: 16rem;

    svg {
      min-width: 1.4rem;
      min-height: 1.4rem;
    }

    span {
      font: {
        size: 1.4rem;
      }
      line-height: 1.3em;
    }

    &:hover {
      color: $black;

      span {
        color: $black;
      }
    }

    &--disabled {
      @extend .btn-user;
      pointer-events: none;
    }
  }

  &-dashed {
    @include  flexify($align: center);
    gap: 1rem;
    width: 100%;
    padding: 1.25rem 1.5rem;
    border-radius: 1rem;
    border: 1px dashed $grey-border;
    cursor: pointer;

    & span {
      color: $grey-txt;
      font-weight: $fw-sb;
    }
  }
}

