.pwa {
  position: fixed;
  top: 0;
  left: 0;
  background-color: $grey-main;
  z-index: 1000;

  &-wrap {
    position: relative;
  }

  &-btn {
    padding: 1rem 1rem 1rem 2rem;
    background: #fff;
    border-radius: 1rem;
    border: 1px solid $blue-border;
    margin: auto;

    & span {
      @include SmallTablet {
        margin-right: 1rem;
      }

      @include Phone {
        font-size: 1.2rem;
        line-height: 1.56rem;
      }
    }

    &__close {
      @include SmallTablet {
        position: absolute;
        right: 0;
      }
    }

    &__icon {
      width: 3.2rem;
      height: 3.2rem;
    }

    &__arrow {
      width: 2rem;
      height: 2rem;
    }
  }
}