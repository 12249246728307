@use 'sass:map';

.progress-bar {
  height: .5rem;
  width: 100%;
  background-color: $green-alert-border;
  position: absolute;
  top: 50%;
  left: 0;
  bottom: 0;
  right: 0;
  transform: translateY(-50%);

  &__track {
    background-color: $success;
    position: absolute;
    top: 50%;
    height: .5rem;
    left: 0;
    bottom: 0;
    max-width: 100%;
    transform: translateY(-50%);
    transition: width .4s;
    border-radius: 1rem;
  }

  &__wrap {
    position: relative;
    color: $success;
    @include flexify($justify: space-between, $align: center);
  }

  &__control {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    border: .5rem solid $green-alert-border;
    background-color: $white;
    color: $white;
    z-index: 1;
    transition: .3s;
    outline: .3rem solid $white;

    @extend .flex-center;

    svg {
      opacity: 0;
    }

    &.active {
      border-width: 1px;
      background-color: $success;
      padding: .3rem;
      svg {
        opacity: 1;
      }
    }
  }
}


.loan-progress {
  &-wrap {
    width: 100%;
    border-radius: 1rem;
    overflow: hidden;
    @include flexify($align: center);
  }

  &-indicator {
    height: .6rem;
    flex-grow: 1;
    background-color: $green-elements;
  }
}

@each $color, $val in $loanProgressColors {
  .loan-progress-indicator.#{$color} {
    background-color: mix(map.get($val, 'color'), map.get($val, 'border'));
  }
}
