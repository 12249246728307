$ff-base: ProximaNovaRegular, sans-serif;
$ff-sb: ProximaNovaSemibold, sans-serif;
$ff-b: ProximaNovaBold, sans-serif;

$fw-r: 400;
$fw-sb: 600;
$fw-b: 700;
$fwTypes: (
        'r': $fw-r,
        'sb': $fw-sb,
        'b': $fw-b,
);

$ffTypes: (
        'r': $ff-base,
        'sb': $ff-sb,
        'b': $ff-b,
);

*::selection {
  background: lighten($primary, 10%);
  color: $white;
}

a, .link,
.link-primary {
  color: $blue;
  cursor: pointer;
  font: {
    family: $ff-sb;
    weight: $fw-sb;
    size: 1.4rem;
  }
  line-height: 1em;
  border-bottom: 1px solid;
  align-self: center;
  transition: color .3s ease;

  &:hover {
    color: $blue-hover;
  }
}

.txt {
  font: {
    weight: $fw-r;
    size: 1.4rem;
  }
  line-height: 1.4em;
  color: $black;
  text-decoration: none;

  &-xs {
    font-size: 1.2rem;
    line-height: 1.3em;
  }

  &-md-18 {
    font-size: 1.8rem;
    line-height: 1.2em;
  }

  &-md-14 {
    font-size: 1.4rem;
    line-height: 2.4rem;
  }

  &-md-16 {
    font-size: 1.6rem;
    line-height: 2.2rem;
  }

  &-md {
    font-size: 2rem;
    line-height: 2.4rem;
  }

  &-base {
    font-size: 1rem;
    line-height: 1.4rem;
  }

  &-uppercase {
    text-transform: uppercase;
  }

  &-capitalize {
    text-transform: capitalize;
  }

  &-underline {
    text-decoration: underline;
  }

  &-crossed {
    text-decoration: line-through !important;
  }

  &-ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &-nowrap {
    white-space: nowrap;
  }
}

@each $align, $val in $aligns {
  .txt-#{$align} {
    text-align: $val;
  }
}

@each $fw, $val in $fwTypes {
  .txt-#{$fw} {
    font-weight: $val;
  }
}

@each $ff, $val in $ffTypes {
  .txt-#{$ff} {
    font-family: $val;
  }
}

@each $color, $val in $colors {
  .txt-#{$color} {
    color: $val;
  }

  .link-#{$color} {
    cursor: pointer;
    color: $val;

    &:hover {
      color: darken($val, 5%);
    }
  }
}

.error-text {
  @extend .txt-base, .txt-error;
}

h1,
.form-title {
  color: $black;
  margin: {
    top: 0;
    bottom: 0;
  }
  font: {
    family: $ff-b;
    weight: $fw-b;
    size: 3.2rem;
  }
  line-height: 1.31em;

  @include Phone {
    font: {
      family: $ff-sb;
      weight: $fw-sb;
      size: 2rem;
    }
  }
}

.gradient-title {
  letter-spacing: .2rem;
  text-align: center;
  font: {
    weight: 900;
    size: 22vmin;
  }
  color: #fff;
  opacity: .8;
  background-size: 400% 400%;
  text-shadow: 0 0 linear-gradient(-45deg, hsl(223, 90%, 50%), hsl(283, 90%, 50%), hsl(343, 90%, 50%), $primary);;
  background-image: linear-gradient(-45deg, hsl(223, 90%, 50%), hsl(283, 90%, 50%), hsl(343, 90%, 50%), $primary);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation-name: waving;
  animation-duration: 4s;
  animation-timing-function: ease;
  animation-delay: 0;
  animation-iteration-count: infinite;
}

.server-error-message {
  @extend .txt-error;
}
