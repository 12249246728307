.form-group {
  @include flexify($wrap: nowrap);
  flex-shrink: 0;
  gap: 1rem;

  .select-box {
    max-width: 10rem;
  }

  &.email {
    gap: 1rem;
    & .input-box {
      max-width: 29rem;
      min-width: 14rem;
    }
    & .select-box {
      max-width: 14rem;
    }
  }
}

.form__wrapper {
  position: relative;
  width: calc(100% + 3rem);
  max-width: 54rem;
  align-self: center;
  font-size: 1.4rem;
  background-color: $white;
  padding: 4rem 5rem;
  border-radius: 2rem 2rem 0 0;

  &::after {
    content: '';
    position: absolute;
    top: 90%;
    left: 0;
    right: 0;
    bottom: -50rem;
    background-color: $white;
    z-index: -1;
  }

  @include flexify($column: true, $justify: center);

  .form-title {
    margin-bottom: 2rem;

    @include SmallTablet {
      margin-bottom: 2.5rem;
    }
  }

  form:not(.slider-wrapper),
  .form{
    width: 100%;
    gap: 2.5rem;
    @include flexify($column: true, $justify: center);
  }

  @include SmallTablet {
    margin: auto;
    width: 100%;
    border-radius: 2rem;

    &::after {
      content: none;
    }
  }
  @include Phone {
    padding: 1.5rem 1.5rem 3rem;
  }
}

.request-status-section {
  position: relative;
  max-width: 54rem;
  width: calc(100% + 3rem);
  color: $black;
  font-size: 1.4rem;
  padding: 4rem 5rem;
  background-color: $white;
  border-radius: 2rem;

  @include flexify($column: true, $align: flex-start);

  .icon {
    width: 4rem;
    height: 4rem;
  }

  svg {
    color: $primary;
    width: 100%;
    height: 100%;
  }

  & > *:last-child {
    margin-bottom: 0 !important;
  }

  @include SmallTablet {
    margin: auto;
    width: 100%;
    border-radius: 2rem;
  }
  @include Phone {
    padding: 3rem 1.5rem 4rem;

    &.auth {
      padding: 2.5rem 1.5rem;
    }
  }
}

.request-status-text {
  font-size: 1.4rem;
  line-height: 1.4em;
  color: $black;
}

.request-status-footer {
  @include flexify($justify: flex-start);
  width: 100%;

  .moratorium-wrap {
    width: calc(100% + 2.5rem);
    margin: 2rem -1.25rem 0;

    & > div {
      width: calc(33% - 2.5rem);
      margin: 0 1.25rem;
    }
  }
}

.account {
  height: 100%;
  width: 100%;
  flex-grow: 1;
  @include flexify($column: true, $justify: flex-start, $align: flex-start);

  .form__wrapper {
    @extend .request-status-section;
    padding: 4rem 5rem;
    max-width: 54rem;
    width: calc(100% + 3rem);
    margin: 0 -1.5rem;

    @include Phone {
      padding: 3rem 1.5rem 4rem;
    }

    &.with-alert {
      border-radius: 2rem;
      &::after {
        content: none;
      }
    }
  }
}
