*, ::after, ::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

input {
  outline: none;
  color: inherit;
  font-size: inherit;

  &[type='file'] {
    display: none;
  }

  &:focus {
    outline: 0;
    outline-offset: 0;
  }
}

button {
  font-family: inherit;
  border: none;
  background: none;
  font-size: inherit;
}

ul, ol {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;

  &:active {
    color: inherit;
  }
}