// COLORS //
$white: #FEFEFE;
$black: #0C122A;
$grey-main: #F3F7FD;
$grey-hover: #E9E9E9;
$grey-txt: #697395;
$grey-txt-footer: #EFEFEF;
$grey-border: #BAC3D5;
$grey-border-light: #E3E4EC;
$grey-dis: #E3E4EC;
$grey-light-bg: #F7F7FA;
$grey-button: #E3ECF2;
$grey-pill: #F3F2FB;
$purple-bg: #BDD0FF;

$green-alert-border: #E6F2E8;

$red: #EA1717;
$red-dark: #923131;
$red-light: #FFF6F6;
$red-alert-border: #F7DCDC;
$green: #00752B;
$green-light: #F7FDF7;
$green-elements: #C4E9D1;
$yellow-alert-bg: #FDFAE3;
$yellow-alert-text: #4E451A;
$yellow-alert-border: #FAEFC7;

$blue-light: #F1F7FC;

$blue: #4D64A7;
$blue-hover: #4464A6;
$blue-border: #CFD9F3;
$blue-alert-bg: #E4EFFF;
$blue-alert-text: #34668E;
$footer-bg: #233467;
$blue-accent: #475B96;



$primary: $blue;
$primary-alert: ('bg': $grey-main, 'color': $primary);

$secondary: $grey-txt;

$error: $red;
$error-field: ('color': $error, 'border': $error,);
$error-alert: ('bg': $red-light, 'color': $error, 'button': $red-alert-border, 'hover': $error);

$success: $green;
$success-field: ('color': $success, 'border': $success,);
$success-alert: ('bg': $green-light, 'color': $success, 'button': $green-alert-border, 'hover': $success);

$warn-normal: #ffd53c;
$warn: $yellow-alert-bg;
$warn-field: ('color': $yellow-alert-text, 'border': $yellow-alert-text,);
$warn-alert: ('bg': $yellow-alert-bg, 'color': $yellow-alert-text, 'button': $yellow-alert-border, 'hover': $yellow-alert-text);

$info: $blue-alert-text;
$info-field: ('color': $info, 'border': $blue-light,);
$info-alert: ('bg': $blue-alert-bg, 'color': $info, 'button': $blue-border, 'hover': $blue);

$default: lighten($grey-border, 18%);
$default-field: ('color': $grey-txt, 'border': $grey-border,);
$default-alert: ('bg': $default, 'color': $grey-txt,);

$like-alert: ('bg': transparent, color: $grey-txt,);

$colors: (
        'error': $error,
        'warn': $warn-normal,
        'warning': $warn,
        'success': $success,
        'info': $info,
        'primary': $primary,
        'secondary': $grey-txt,
        'white': $white,
        'black': $black,
        'grey': $grey-border,
);

$loanProgressColors: (
        'error': $error-field,
        'warn': $warn-field,
        'warning': $warn-field,
        'success': $success-field,
        'info': $info-field,
);


$loanStateIndicators: (
        'active': $success-alert,
        'sold': $warn-alert,
        'defaulted': $error-alert,
        'pending': $info-alert,
        'cancelled': $warn-alert,
        'closed': $default-alert,
        'error': $error-alert,
        'warn': $warn-alert,
        'warning': $warn-alert,
        'success': $success-alert,
        'default': $default-alert,
        'info': $info-alert,
        'primary': $primary-alert,
        'like': $like-alert,
);

// BREAKPOINTS //
$bp-xs: 480px;
$bp-sm: 768px;
$bp-md: 991px;
$bp-lg: 1024px;
$bp-xl: 1440px;
$container-width: 1180px;
$grid-padding: 1.5rem;

// HELPERS //
$flexAligns: (
        'start': flex-start,
        'center': center,
        'end': flex-end,
        'stretch': stretch,
        'between': space-between,
        'around': space-around,
);

$aligns: (
        'right': right,
        'center': center,
        'left': left,
        'justify': justify,
);

///for progress in account loan need remove!
$fb-color: #EA1717;
$grey-light: #BAC3D5;
$blue-alert-border: #E3ECF2;
$secondary-light: #FAEFC7;
