.copy {
  padding: 1.2rem;
  background-color: $blue-light;
  border-radius: 1rem;

  &-link {
    position: relative;
    cursor: pointer;
    padding-left: 1.4rem;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: -.4rem;
      bottom: -.4rem;
      width: .1rem;
      background-color: $blue-border;
    }

    & span {
      transition: color .3s ease;
    }

    & svg {
      & path {
        transition: fill .3s ease;
      }
    }

    &:hover {
      & span {
        color: $black;
      }

      & svg {
        & path {
          fill: $black;
        }
      }
    }
  }
}