.react-tooltip {
  padding: 1.5rem !important;
  background-color: $white !important;
  border: 1px solid $grey-border !important;
  border-radius: 1rem !important;
  opacity: 1 !important;
  width: 19rem !important;

  &-arrow {
    display: none !important;
  }
}

.payment_date-tooltip {
  margin-left: 5px;
  position: relative;

  &-confirm {
    width: 10px;
    margin-left: 2px;
  }
}

.payment_date-tooltip-icon::after {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
}
