.radio-box {
  [type='radio'] {
    cursor: pointer;
    position: absolute;
    opacity: 0;
  }

  &__label {
    margin-bottom: 1rem;
    font: {
      family: $ff-base;
      weight: $fw-r;
    }
  }

  &__option span,
  &__label {
    @extend .field-lbl;
  }

  &__options {
    @include flexify($align: center);
    gap: 2rem;
  }

  &__option {
    cursor: pointer;
    position: relative;
    min-height: 2rem;
    @include flexify($dirsReverse: true, $align: center);

    span {
      text-transform: capitalize;
      font: {
        family: $ff-base;
        weight: $fw-r;
      }
      color: $grey-txt;
    }

    &:before {
      content: '';
      display: block;
      cursor: pointer;
      position: absolute;
      transition: all 0.3s ease;
      left: 0;
      top: 0;
      width: 2rem;
      height: 2rem;
      background-color: $white;
      border: {
        radius: 50%;
        width: 1px;
        style: solid;
        color: $primary;
      }
    }

    &_label {
      margin-left: 3rem;
    }

    &_checked {
      &:before {
        border-width: 0.6rem;
      }
    }

    //&:active {
    //  &:before {
    //    transform: scale(.85);
    //  }
    //}

    &:hover {
      &:before {
        box-shadow: 0 0 0 1px transparentize($primary, 0);
      }
    }
  }
}
