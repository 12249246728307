.modal-core,
.hv-modal.hv-show-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparentize($black, .5);
  -webkit-backdrop-filter: blur(.25rem);
  backdrop-filter: blur(.25rem);
  z-index: 1500;
  animation-name: appear;
  padding: $grid-padding;
  @include flexify($align: center, $justify: center);

  .hv-close-button,
  &__closing {
    animation-name: disappearance;

    .modal-core__wrapper {
      animation-name: slide-out;
    }
  }

  .hv-modal-content,
  &__wrapper {
    background-color: $white;
    position: static;
    padding: 2rem;
    max-width: 54rem;
    width: 100%;
    height: auto;
    max-height: 100%;
    animation-name: slide-in;
    border-radius: 1rem;
    box-shadow: 0 0 2rem .1rem transparentize($black, .6);
    transform: none;
    @include flexify($column: true);

    @include SmallTablet {
      padding: 3rem;
    }

    &--webcam {
      max-width: 80rem;
    }

    &--webcam {
      padding: 0;

      [class*='__header'] {
        padding: 2rem 2rem 1rem;
      }

      @include SmallTablet {
        padding: 0;
      }
    }

    &--instructions {
      .instructions {
        &-block {
          margin-left: -.75rem;
        }
        &-item {
          width: calc(50% - 1.5rem);
          margin: 0 .75rem;

          @include Phone {
            width: 100%;
          }
        }
      }
    }

    &.instruction-repay {
      padding: 2rem;
      gap: 0;

      @include SmallPhone {
        padding: 1rem 1rem 2rem;
      }

      & .instruction-repay__text p {
        margin-bottom: .8rem;

        &:last-child {
          margin-bottom: 0;
        }
      }

      & > *:last-child {
        margin: 0;
      }
    }

    .instruction-icon {
      max-width: 4rem;
      width: 100%;
      min-height: 4rem;
      border-radius: 1rem;
      border: 1px solid $grey-hover;
    }

    &--bank-details {
      .modal-core__content {
        overflow: visible;
      }
    }

    &--exit .modal-core__header {
      margin-bottom: 0;
    }

    &.email_modal {
      .modal-core__content {
        overflow: visible;
      }
      .modal-core__footer {
        display: none;
      }
    }

    &--email-verified .modal-core__footer {
      display: none;
    }

    &--user-matched .modal-core__footer {
      display: none;
    }

    &.form__wrapper {
      &::after {
        content: none;
      }
    }
  }

  .hv-title,
  &__title {
    margin: 0;
    font: {
      family: $ff-sb;
      size: 2rem;
      weight: $fw-sb;
    }
  }

  &__header {
    @include flexify($justify: space-between);
    margin-bottom: 1rem;
    gap: 1rem;
  }

  .hv-close-button,
  &__close {
    cursor: pointer;
    color: lighten($grey-border, 10);
    height: 2rem;
    flex-shrink: 0;
    flex-basis: 2rem;
    @extend .user-control-effect;

    &:hover {
      color: lighten($grey-border, 15%);
    }
  }

  .hv-close-button {
    align-self: flex-end;
    font-size: 0;
    content: url("data:image/svg+xml; utf8, <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><rect x='16.1426' y='17.5566' width='20' height='2' rx='1' transform='rotate(-135 16.1426 17.5566)' fill='rgb(234, 234, 234)'/><rect x='2.1416' y='16.5566' width='20' height='2' rx='1' transform='rotate(-45 2.1416 16.5566)' fill='rgb(234, 234, 234)'/></svg>");

    &:hover {
      background-color: transparent;
      content: url("data:image/svg+xml; utf8, <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><rect x='16.1426' y='17.5566' width='20' height='2' rx='1' transform='rotate(-135 16.1426 17.5566)' fill='rgb(224, 224, 224)'/><rect x='2.1416' y='16.5566' width='20' height='2' rx='1' transform='rotate(-45 2.1416 16.5566)' fill='rgb(224, 224, 224)'/></svg>");
    }
  }

  .hv-instruction-div,
  &__content {
    max-width: 100%;
    overflow: auto;
    flex-grow: 1;
    font-size: 1.4rem;
    height: auto;

    &.payment-slip-modal {
      overflow: auto;

      & img {
        max-width: 100%;
        height: auto;
        object-fit: cover;
        border-radius: .5rem;
      }
    }
  }

  & .email {
    gap: 1rem;

    @include Phone {
      gap: .5rem;
    }

    & .input-box {
      max-width: 33rem;
    }
    & .select-box {
      input {
        font-size: 1.2rem;

        @include Phone {
          padding-right: 0;
          padding-left: 1rem;
          width: 100%;
        }
      }

      @include Phone {
        & [class*=-indicatorContainer] {
          padding: .5rem 1rem .5rem .8rem;
          &:before {
            width: 1rem;
            content: url("data:image/svg+xml; utf8,  <svg width='10' height='10' viewBox='0 0 16 10' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1 1L8 8L15 1' stroke='rgb(105, 115, 149)' stroke-width='2' stroke-linecap='round'/></svg>");
          }
        }
        & [class$=-ValueContainer] {
          padding-left: 1rem;
        }
        & [class*=-singleValue] {
          font-size: 1.2rem;
        }
      }
    }
  }

.overflow {
  overflow: visible;
}
  video {
    max-width: 100%;
  }

  .hv-instruction-div + div {
    position: static!important;
  }

  .hv-instruction-small-div-face-text {
    margin: {
      left: 0;
      right: 0;
      bottom: 1rem;
      top: 0;
    }
    max-width: 100%;
    gap: 1rem;
    font-size: 1.6rem;
    @include flexify($align: center);

    img {
      display: none;
    }

    &:before {
      content: "";
      display: block;
      border-radius: 50%;
      background-color: $primary;
      width: .7rem;
      height: .7rem;
    }
  }

  .hv-instruction-selfie {
    display: none;
  }

  .hv-modal-footer,
  &__footer {
    position: static;
    margin-top: 2.5rem;
    @include flexify($column: true, $align: center);

    .btn {
      width: 100%;
    }
  }

  .hv-modal-footer {
    margin-top: 2rem;
    .footertext {
      font-size: 1rem;
    }
  }

  &__actions {
    @include flexify($column: true, $align: center, $justify: center);
  }

  .hv-proceed-btn {
    @extend .btn, .btn--primary;
    max-width: 100%;
    height: auto;
    margin-top: 3rem;
    margin-right: 0;
    width: 100%;
    font-size: 1.6rem;
    position: static!important;
  }

  .hv-divforcover {
    position: relative!important;
    bottom: auto !important;
    margin-left: 0 !important;
    margin-top: 3rem;

    a {
      display: flex;
      position: relative;
      min-height: 4.5rem;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      @extend .btn, .btn--primary;

      &:before {
        content: 'Take a photo'
      }

      &:hover {
        &:after {
          content: none;
        }
      }
    }

    a:not(#hv-camera-capture-button) {
      background-color: $grey-dis;
      background-image: none;
      pointer-events: none;
    }
  }

  img.hv-btnposition {
    opacity: 0;
    height: 3rem;
    position: absolute;
    width: 100% !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &.show-app-header {
    top: 5rem;

    @include Tablet {
      top: 8rem;
    }
  }

  @extend .webview
}

.modal-core__title {
  display: flex;
}

.referral-modal {
  &__title {
    @include flexify($align: center, $justify: center);

    margin-bottom: 1.5rem;

    & svg {
      width: 8.9rem;
      height: auto;
    }
  }

  &__important {
    background-color: $grey-main;
    padding: 1.5rem;
    border-radius: .5rem;
  }

  .modal-core__footer {
    display: none;
  }
}

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes disappearance {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes slide-in {
  from {
    transform: translateY(-150px);
  }
  to {
    transform: translateY(0);
  }
}


@keyframes slide-out {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-150px);
  }
}
