.input-box__calendar {

  [class*='_element_right'] {
    background-color: transparent;
    color: $grey-txt;
    height: 100%;
    width: 2rem;
    flex-basis: 2rem;
    padding: 0 1rem;
  }

  .react-datepicker-wrapper {
    flex-grow: 1;
    width: auto;
  }

  input {
    width: 100%;
  }

  &:nth-child(2) {
    z-index: 1;
  }
}

.react-datepicker {
  border :{
    width: 1px;
    style: solid;
    radius: 1rem;
    color: $grey-border;
  }
  padding: 0;
  width: 100%;
  max-width: 29rem;
  background-color: $white;
  overflow: visible;

  &-popper {
    width: 100%;
    max-width: 29rem;
    z-index: 10000;
  }

  &__triangle {
    display: none;
  }

  &__header,
  &__header:not(&__header--has-time-select) {
    padding: 1.5rem;
    border: {
      top-left-radius: 1rem;
      top-right-radius: 1rem;
    }
    background-color: $white;
    border-bottom: 1px solid lighten($grey-border, 5);
  }

  &__header__dropdown {
    @include flexify($align:center);
    gap: 1rem;
  }

  &__month-container,
  &__month-read-view,
  &__year-read-view {
    float: none;
    width: 100%;
  }

  &__month-read-view,
  &__year-read-view {
    gap: .5rem;
    border: 1px solid $grey-border;
    //@extend .pill-helper, .pill-default;
    @include flexify($align: center, $justify: flex-start, $dirsReverse: true);
    visibility: visible !important;
    border-radius: 1rem;
    padding: 1.2rem;

    &--selected-month, &--selected-year {
      color: $black;
      font-size: 1.4rem;
      font-family: $ff-sb;
    }
  }

  &,
  &__current-month {
    @extend .field-lbl;
    border-radius: 1rem;
  }

  &__current-month {
    display: none;
  }

  &__navigation {
    display: none;
  }

  &__month {
    margin: 0;
    padding: 1.5rem;
  }

  &__day-names {
    margin-bottom: 0;
  }

  &__week, {
    @include flexify($justify: space-between);
  }


  &__day-names {
    display: none;
  }

  &__day-name,
  &__day,
  &__month-text,
  &__quarter-text,
  &__year-text{
    width: 3.4rem;
    height: 3.4rem;
    margin: 0;
    border-radius: .5rem !important;
    @include flexify($align: center, $justify: center);

    &--outside-month {
      color: $grey-light;
    }

    &:hover {
      background-color: $blue-alert-bg;
    }
  }

  &__day--selected,
  &__day--keyboard-selected,
  &__month-text--keyboard-selected,
  &__quarter-text--keyboard-selected,
  &__year-text--keyboard-selected {
    background-color: $primary;
    color: $white;
    font-weight: $fw-r;

    &:hover {
      background-color: $primary;
    }
  }

  &__month-dropdown-container,
  &__year-dropdown-container {
    flex-grow: 1;
    position: relative;

    &:active {
      transform: none;
    }
  }

  &__year-dropdown,
  &__month-dropdown {
    @extend .select-menu;
    width: 100%;
    top: calc(100% + 4px);
    height: 17rem;
    overflow: visible;
    overflow-y: auto;
    z-index: 8;
  }

  &__month-dropdown, &__year-dropdown {
    left: 0;
  }

  //&__year-dropdown {
  //  right: 0;
  //}

  &__month-read-view {
    visibility: visible !important; //inline style degradation
  }

  &__month-option, &__year-option {
    text-align: left;
  }

  &__year-option,
  &__month-option {
    @extend .select-menu-option;
    padding: .5rem 1.5rem;

    [class*='navigation'] {
      top: 0;

      &[class*='--years'] {
        &:before {
          content:"";
          display: block;
          width: 1.5rem;
          height: 1.5rem;
          border:{
            right: .3rem solid lighten($grey-border, 10);
            bottom: .3rem solid lighten($grey-border, 10);
          }
        }

        &[class*='-previous'] {
          &:before {
            transform: rotate(45deg);
          }
        }

        &[class*='-upcoming'] {
          &:before {
            transform: rotate(-135deg);
          }
        }
      }
    }

    &--selected {
      display: none;

      &_month, &_year {
        color: $white;
        background-color: $blue;
      }
    }
  }

  &__year-option:first-of-type,
  &__month-option:first-of-type {
    border: {
      top-left-radius: 1rem;
      top-right-radius: 1rem;
    }
  }

  &__year-option:last-of-type,
  &__month-option:last-of-type {
    border: {
      bottom-left-radius: 1rem;
      bottom-right-radius: 1rem;
    }
  }

  &__year-read-view--down-arrow,
  &__month-read-view--down-arrow,
  &__month-year-read-view--down-arrow {
    @include flexify($justify: center, $align: center);
    border: none;
    position: absolute;
    right: 12px;
    top: 13px;
    transform: none;
    width: 1.6rem;
    height: 1.6rem;
    &:after {
      content: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="10" viewBox="0 0 16 10" fill="none"><path d="M1 1L8 8L15 1" stroke="%23697395" stroke-width="2" stroke-linecap="round"/></svg>');
      @include flexify($justify: center, $align: center);
      width: 1.6rem;
      height: 1.6rem;
    }
  }

  &__month-dropdown-container, &__year-dropdown-container {
    margin: 0;
  }

  &__header--custom {
    & .header-field {
      position: relative;
      width: 100%;

      & [class$='option'] {
        text-align: left;
      }

      & svg {
        position: absolute;
        top: 1.9rem;
        right: 1.3rem;
      }

      & [class$='-indicatorContainer'] {
        display: none;
      }

      &.active {
        & [class$='-control'] {
          border-color: $primary;
        }

        & svg {
          transform: scale(-1);
        }

        & .select-box__label {
          color: $primary;
        }
      }
    }
  }
}

.picker {
  mask-image: linear-gradient(to top, transparent, transparent 5%, white 30%, white 30%, transparent 95%, transparent) !important;

  &-item {
    font-size: 1.4rem;
    font-family: $ff-base;
    color: $grey-txt;
    &--selected {
      font-size: 2rem;
      font-family: $ff-b;
      color: $black;
    }
    &--below-1, &--above-1 {
      font-size: 1.8rem;
    }
    &--below-2, &--above-2 {
      font-size: 1.6rem;
    }
  }
}

