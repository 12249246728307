@import "src/assets/styles/mixins";
@import "src/assets/styles/variables";

.development-info {
  min-width: 150px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10001;
  background-color: rgba(0, 0, 0, 0.8);
  align-items: center;
  padding: 5px;
  border-radius: 0 0 5px 5px;

  button {
    background: #FFFFFF;
    border: 1px solid;
  }
  input{
    color: black;
  }

  &__links {
    display: flex;
    align-items: center;
  }

  span {
    font-size: 10px;
    color: white;
  }

  .paths {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    color: #03ad00;
    cursor: pointer;

    button:hover {
      transform: scale(1.1);
    }

    .base-url__wrapper {
      display: flex;
      margin: 8px 0;

      .base-url-input {
        width: 80%;
      }
    }


    a:hover {
      background-color: rgba(0, 0, 0, 0.8);
    }

    .active {
      color: deepskyblue;
    }

    .color-yellow {
      color: yellow;
    }
  }

  &__icon {
    cursor: pointer;
    margin: auto 5px;
  }

  .stroke {
    path {
      stroke: red;
    }
  }

  .fill {
    path {
      fill: red;
    }
  }

  &__close {
    //margin-left: auto;
  }

  &__empty {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10001;
    color: transparent;
    background-color: transparent;
    border: none;
  }
}

.stepper-btn {
  display: flex;
  align-items: center;
  color: white;
}

#step {
  height: 18px;
  width: 18px;
}

.generate-btn {
  font-size: 12px;
  padding: 5px;
  margin: 4px 4px 0 0;
  position: absolute;
  right: 0;
  cursor: pointer;
}

.generate-phone-btn {
  font-size: 12px;
  padding: 5px;
  margin: 0 -10px;
  cursor: pointer;
}