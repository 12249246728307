.webcam {
  position: relative;
  @include flexify($column: true);
  video {
    max-width: 100%;
    max-height: 60vh;
    width: 100%;
  }
  img {
    margin: auto;
    max-height: 60vh;
  }

  @include  SmallTablet {
    video, img {
      max-height: none;
    }
  }

  &-footer {
    position: absolute;
    bottom: -2rem;
    left: 0;
    right: 0;
    padding: 1rem;
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
    background-color: rgba(255, 255, 255, .7);
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(10px);
    @include flexify($align: center, $justify: space-around);
  }

  &-container {
    overflow: hidden;

    & video, {
      transform: scaleX(-1);
      max-height: 50vh;
    }
  }

  &__circle {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 21rem;
    height: 27rem;
    border-radius: 50%;
    backdrop-filter: none;
    z-index: 2;
    transform: translate(-50%, -50%);
    background-color: transparent;
    box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.6);

    @media screen and (max-width: 400px) {
      width: 18rem;
      height: 23rem;
    }
  }
}
