.input-checkbox {
  @extend .field-helper;

  input {
    opacity: 0;
    position: absolute;
    min-width: 0;
  }

  &__label {
    cursor: pointer;
    @extend .field-lbl;
    white-space: normal;
    color: $black;
    font-weight: $fw-r;
    @include flexify($align: center);

    &:hover,
    &:focus {
      [class*='__element'] {
        border-width: 2px;
      }
    }
  }

  &__element {
    position: relative;
    border: 1px solid $primary;
    border-radius: .5rem;
    width: 2rem;
    height: 2rem;
    align-self: flex-start;
    margin-right: 1rem;
    flex-shrink: 0;

    &:after {
      content: "";
      position: absolute;
      top: calc(50% - 1px);
      left: 50%;
      width: .7rem;
      height: 1rem;
      border: {
        right: 2px solid $white;
        bottom: 2px solid $white;
      }
      transform: translate(-50%, -50%) rotate(45deg);
      opacity: 0;
      transition: all .3s ease;
    }
  }

  &__checked {
    .input-checkbox__element {
      border-color: $primary;
      background-color: $primary;
      &:after {
        opacity: 1;
      }
    }
  }

  &__invalid {
    @extend .invalid-field-helper;
  }
}
