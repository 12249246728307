.card {
  position: relative;
  flex-grow: 1;
  padding: 2.5rem 1.5rem;
  font-size: 1.4rem;
  line-height: 1.8rem;
  background-color: $white;
  border-radius: 2rem;
  overflow: hidden;

  @include SmallTablet {
    min-width: 30rem;
  }

  [class*='pill-'] {
    font-size: 1rem;
    line-height: 1.3em;
    border-radius: 1rem;
  }

  @include Phone {
    &.bank-account {
      min-width: 100%;
      max-width: 100%;
    }
  }
}

.card-secondary {
  @extend .card;
  @include flexify();

  padding: 1rem 1rem 2rem;
  background-color: $grey-main;
  border-radius: 1rem;

  &.card-desktop {
    @media (max-width: $bp-sm) {
      padding: 0;
      border: none;
      background-color: transparent;
    }
  }

  .link {
    width: fit-content;
    align-self: normal;
  }

  &-image-wrapper {
    @include flexify($justify: center, $align: center);
    width: 100%;
    max-width: 27rem;
    max-height: 14.2rem;
    border-radius: 1rem;
    overflow: hidden;
    background: {
      position: center;
      size: cover;
      repeat: no-repeat;
    }

    img,
    svg {
      max-width: 100%;
    }
  }

  [type='submit'] {
    margin-top: 2rem;
  }

  .btn--primary {
    width: 100%;
    max-width: 100%;
  }

  &.invalid {
    @extend .invalid-card-helper;
  }

  @include Tablet {
    padding: 2rem;
  }
}

.loan-history-card {
  gap: 1rem;
  padding: 1.5rem;

  @extend .card;

  flex-grow: 0;

  @include flexify($column: true);

  h6 {
    font-size: 1.6rem;
  }

  &-body {
    overflow: hidden;
    transition: all 0.3s;
    width: 100%;
    gap: 1rem;

    @include flexify($column: true);

    @include SmallTablet {
      @include flexify($column: false);
    }

    @include Tablet {
      gap: 3rem;
    }

    &.collapsed {
      height: 0;
      opacity: 0;
    }
  }
}

.payment-history__wrap {
  @include flexify($align: flex-start, $wrap: wrap);

  .payment-card {
    @extend .card;
    gap: 1.5rem;
    width: 100%;
    font: {
      family: $ff-sb;
      size: 1.2rem;
      weight: $fw-sb;
    }

    .lined-card-row:first-child {
      border: none;
      padding: 0;
    }

    @include SmallTablet {
      margin: 0 1.5rem 3rem;
      max-width: calc(50% - 3rem);
    }
  }

  @include SmallTablet {
    margin: 0 -1.5rem;
  }
}

.fb-styled-section {
  background-color: $fb-color;
  padding: 25px;
  max-width: 700px;
  width: 100%;
  box-shadow: 0 2px 10px transparentize($secondary, 0.75);
  border-radius: 10px;

  @include flexify($align: flex-start);

  &__btn {
    background-color: $white;
    padding: 5px 15px;
    color: $fb-color;
    width: 100%;
    max-width: 250px;
    box-shadow: 0 2px 10px transparentize($secondary, 0.75);
    border-radius: 4px;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    height: 40px;
    text-align: center;

    @include flexify($justify: center, $align: center);

    svg {
      margin-right: 15px;
    }
  }

  .btn__icon {
    transform: translate(15px, -15px);
  }

  .item {
    gap: 20px;
    @include flexify($column: true);

    @media (min-width: $bp-sm) {
      @include flexify($column: false, $align: center, $justify: space-between);
      p {
        max-width: 50%;
      }
    }
  }
}

.card-group {
  @extend .card;
  @include flexify($wrap: wrap, $justify: space-between);
  flex-grow: 0;
  width: calc(100% + 3rem);
  padding: 2.5rem 1.5rem;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;

  &-item {
    flex: 1 0 100%;
    max-width: 100%;

    &:first-child {
      @include SmallTablet {
        flex: 1 0 59%;
        max-width: 59%;
        padding-right: 5rem;
        position: relative;

        &:after {
          content: '';
          display: block;
          width: 1px;
          height: 100%;
          background-color: lighten($grey-border, 14%);
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
        }
      }
    }

    &:last-child {
      @include SmallTablet {
        flex: 1 0 41%;
        max-width: 41%;
        padding-left: 5rem;
      }
    }
  }

  @include SmallTablet {
    padding: 2.5rem 2rem;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    border-radius: 2rem;
  }
}

.loan-conditions {
  @include flexify($align: center);
  padding: 1rem 2rem;
  border: 2px solid $grey-main;
  border-radius: 1rem;

  .column:not(:nth-child(3)) {
    margin-right: 3rem;

    @include SmallPhone {
      margin-right: 0;
    }
  }

  & > span {
    margin-left: auto;

    @include SmallPhone {
      margin-left: 0;
    }
  }

  @include Phone {
    padding: 1rem;
  }

  .txt {
    @include SmallPhone {
      font: {
        size: 1.2rem;
      }
    }
  }

  @include SmallPhone {
    justify-content: space-between;
  }
}

.loan-conditions-wrapper {
  .loan-conditions {
    justify-content: space-between;
  }
}

.loan-history {
  display: grid;
  gap: 2rem;
  width: 100%;
  margin-bottom: 4rem;

  .loan-history-card {
    padding: 2rem 1.5rem;
    min-width: auto;

    .link {
      align-self: flex-start;
      width: max-content;

      &.txt-end {
        align-self: flex-end;
      }
    }

    &.show {
      grid-row: span 2;
    }

    &-body {
      flex: {
        direction: column;
        wrap: nowrap;
      }
      gap: 1rem;
    }
  }

  @include SmallTablet {
    grid-template-columns: repeat(3, 1fr);
  }

  @include Tablet {
    gap: 3rem;
  }
}

.sold-grid {
  .lined-card-row:first-child {
    border: none;
    padding: 0;
  }
}

.promotion {
  &-title {
    flex-wrap: nowrap;

    & h2 {
      max-width: 22.4rem;
      font-family: $ff-sb;
    }

    @include Phone {
      flex-direction: column;
      flex-wrap: wrap;

      & h2 {
        text-align: center;
      }
    }

    &-bg {
      position: relative;
      flex-direction: row;
      flex-wrap: nowrap;
      padding: 0 2rem 0 7rem;
      background-color: $purple-bg;
      width: 100%;
      border-radius: 1rem;
      margin-bottom: 2rem;
      z-index: 1;

      & > svg {
        max-width: 10rem;
        min-width: 10rem;
        height: auto;
      }

      & h2 {
        text-align: start;
        max-width: 100%;
        padding: 1rem 0;

        @include Phone {
          font-size: 1.6rem;
          padding: 0;
        }
      }

      & span {
        position: absolute;
        top: 0;
        left: 5rem;
        height: 100%;
        width: 100%;
        z-index: -1;

        & svg {
          height: 100%;
          width: 100%;
        }
      }

      @include Phone {
        padding: 1.2rem 1.8rem;
        gap: 0 !important;
      }
    }
  }
  &-label {
    background-color: $red;
    color: $white;
    font-size: 16px;
    font-family: $ff-b;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2.4rem 4rem 1rem 4rem;
    position: absolute;
    left: -45px;
    top: -3px;
    transform: rotate(-45deg);
    width: 14rem;
    text-align: center;
    z-index: 10;
  }

  &-icon {
    width: 4rem;
    height: 4rem;
    & svg {
      width: 100%;
      height: 100%;
    }
  }

  & .pill-warning {
    svg {
      path {
        fill: $yellow-alert-text;
      }
    }
  }
  & .pill-error {
    color: $red-dark;

    svg {
      path {
        fill: $red-dark;
      }
    }
  }
}

.card-bg {
  background-color: $blue-light;
  padding: 1.2rem;
  border-radius: 1rem;
}
