@use "sass:math";

$spinkit-size: 4em !default;
$spinkit-spinner-color: #545980 !default;

.preloader {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, .8);
  border-radius: 10px;
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;

  &.__bootstrap {
    $ref: &;

    display: none;
    position: fixed;
    z-index: 20;

    @at-root {
      body.loading {
        overflow: hidden;

        #{$ref} {
          display: flex;

          ~ * {
            visibility: hidden;
            opacity: 0;
          }
        }
      }
    }
  }

  .sk-chasing-dots {
    $animationDuration: 2.0s;

    width: $spinkit-size;
    height: $spinkit-size;
    position: relative;
    margin: auto;
    text-align: center;
    animation: sk-chasing-dots-rotate $animationDuration infinite linear;

    .sk-child {
      width: #{math.div($spinkit-size, 2)};
      height: #{math.div($spinkit-size, 2)};
      display: inline-block;
      position: absolute;
      top: 0;
      background-color: $spinkit-spinner-color;
      border-radius: 100%;
      animation: sk-chasing-dots-bounce $animationDuration infinite ease-in-out;
    }

    .sk-dot-2 {
      top: auto;
      bottom: 0;
      animation-delay: #{math.div(-$animationDuration, 2)};
    }
  }
}

@keyframes sk-chasing-dots-rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes sk-chasing-dots-bounce {
  0%, 100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.0);
  }
}
