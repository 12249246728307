@use 'sass:map';

[class*='pill-'] {
  @extend .pill-helper;
}

[class*='alerts-item__'],
[class*='badge-item__'] {
  //@extend .alert-helper;
}

@each $color, $val in $loanStateIndicators {
  $border-color: map.get($val, 'color');

  .pill-#{$color} {
    border: 1px solid map.get($val, 'bg');
    background-color: map.get($val, 'bg');
    color: map.get($val, 'color');
  }

  .pill-outlined-#{$color} {
    border: 1px solid map.get($val, 'color');
    outline: 1px solid map.get($val, 'color');
    background-color: map.get($val, 'bg');
    color: $black;
    font-weight: $fw-sb;
    pointer-events: none;
  }

  .pill-loan-#{$color} {
    border: 1px solid $border-color;
    color: map.get($val, 'color');
    font-weight: $fw-sb;
    border-radius: 1rem;
    text-transform: capitalize;
  }

  .badge-item__#{$color} {
    border-color: map.get($val, 'bg');
    color: map.get($val, 'color');
    background-color: map.get($val, 'bg');
  }

  .alerts-item__#{$color} {
    border-color: $border-color;
    color: map.get($val, 'color');
    background-color: map.get($val, 'bg');

    a {
      color: map.get($val, 'color');
      background-color: map.get($val, 'button');
      transition: all .3s ease;
      border: none;

      path {
        fill: map.get($val, 'color');
        transition: fill .3s ease;
      }

      &:hover {
        background-color: map.get($val, 'hover');
        color: $white;

        path {
          fill: $white;
        }
      }
    }

    &__message {
      display: flex;
      font-size: 1.4rem;
      flex-grow: 1;
    }

    &__icon__wrapper {
      max-width: 1.5rem;
      max-height: 1.5rem;

      svg {
        min-width: 1.5rem;
        min-height: 1.5rem;
      }

      &:last-child {
        svg {
          opacity: .7;
          min-width: 1.2rem;
          min-height: 1.2rem;
          cursor: pointer;

          path {
            fill: map.get($val, 'color');
          }
        }
      }
      &.close {
        max-width: 2rem;
        max-height: 2rem;
      }
    }
  }

  .badge-item__#{$color},
  .alerts-item__#{$color} {
    @extend .alert-helper;

    .alerts-message {
      color: map.get($val, 'color');
    }

    .pill-default,
    .btn:not(:disabled) {
      color: map.get($val, 'bg');
      background: {
        image: none;
        color: map.get($val, 'color');
      }

      &:hover {
        background: {
          color: $border-color;
          image: none;
        };

        &,
        & .btn-text {
          color: map.get($val, 'color');
        }
      }
    }

    .btn:disabled {
      cursor: default;
      background: {
        image: none;
        color: darken(map.get($val, 'bg'), 5%);
      }
    }
  }
}

.alerts-icon__wrapper {
  width: 1.6rem;
  height: 1.6rem;
}

.pill-default {
  font-weight: $fw-sb;
  font-size: 1.2rem !important;
  color: $black !important;
}

.email-alert__wrapper {
  & .form__wrapper {
    &:after {
      content: none;
    }
  }
}
