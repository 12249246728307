@import "src/assets/styles/variables";
@import "../variables";

header {
  min-height: 5rem;
  width: 100%;
  @include flexify($align: stretch, $justify: center);

  &.show-install-app-btn {
    margin-top: 7.4rem;
  }

  span, a {
    text-decoration: none;
    border: none;
    font: {
      family: $ff-base;
      weight: $fw-r;
      size: 1.4rem;
    }
    color: $black;
    white-space: nowrap;
    transition: color .3s ease;

    &:hover {
      color: $blue-hover;
    }
  }

  .phone {
    font: {
      family: $ff-sb;
    };
  }

  .navigation {
    height: 100%;
    display: none;

    @include Laptop {
      @include flexify($align: center, $justify: space-evenly);
    }

    a {
      font: {
        size: 1.6rem;
      }

      &:hover,
      &:focus,
      &.active {
        color: $primary;
      }
    }
  }

  @include Tablet {
    min-height: 8rem;
    border-bottom: 1px solid $blue-alert-border;
    background-color: $white;
  }
}

.logo {
  @include flexify($align: center, $justify: center);
  width: 7rem;
  height: 3rem;

  svg {
    width: 100%;
    height: auto;
  }

  @include Tablet {
    width: 10.4rem;
    height: 5rem;
  }
}

.modal-core {
  .sidebar {
    padding: 0;
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    max-width: 30rem;
    width: 100%;
    border-radius: 0;
    gap: 0;

    .modal-core__header {
      @include flexify($align: center);
      padding: .8rem 1.5rem;
      border-bottom: 1px solid $grey-border-light;

      .modal-core__close {
        width: 2rem;
        height: 2rem;

        svg {
          width: 100%;
          height: 100%;

          path {
            fill: $grey-border;
          }
        }
      }

      a {
        margin: 0 1.2rem 0 auto;
        font: {
          weight: $fw-sb;
          size: 1.4rem !important;
        }
      }

      @include Tablet {
        padding: 1.4rem 1.5rem;
      }
    }

    .modal-core__title {
      width: 100%;

      & > div {
        @include flexify($align: center);
      }
    }

    .modal-core__content {
      padding: 2rem 0;
    }

    &-title {
      background-color: lighten($grey-border, 15);
      padding: .5rem 1.5rem;
      margin: .5rem 0;
    }

    .lang-switcher {
      padding: 0 !important;
      @include flexify($column: false);
      font-size: 1.4rem;
      margin-left: auto;
    }

    &-wrapper {
      background-color: $grey-main;
      border-radius: 1rem;
      padding: 1.5rem;
      margin-bottom: 2rem;

      svg {
        path {
          fill: $blue;
        }
      }
    }

    .phone {
      a {
        font: {
          family: $ff-sb;
          size: 1.4rem;
          weight: $fw-sb;
        }
        line-height: 1.3em;
        color: $black;
        text-decoration: none;
        border: none;
      }

      span {
        color: $grey-txt;
        margin-top: .2rem;
      }
    }

    .working-hours {
      span {
        font: {
          family: $ff-sb;
          size: 1.4rem;
          weight: $fw-sb;
        }
        line-height: 1.3em;
        color: $black;
      }
    }

    .lang {
      @include flexify($align: center);
      padding: 0 1.5rem;


      &-switcher {
        margin-left: auto;
      }
    }
  }

  .navigation {
    @include flexify($column: true, $align: flex-start);
    margin-top: 2rem;

    a, button {
      @include flexify($align: center);
      padding: 1.5rem;
      width: 100%;
      border-radius: 0;
      font: {
        family: $ff-sb;
        weight: $fw-sb;
        size: 1.4rem;
      }
      line-height: 1.3em;
      color: $black;
      text: {
        decoration: none;
        align: left;
      }
      border-bottom: none;
      border-top: 1px solid $grey-main;

      &.active {
        color: $primary;
        background-color: transparentize($primary, .9);
      }

      svg {
        margin-left: auto;
      }
    }

    button {
      color: $grey-txt;
    }

    .indicator {
      display: none;
    }
  }

  .subheader {
    flex-grow: 1;
  }
}

.subheader {
  gap: 0;
  overflow-x: scroll;
  padding: .5rem .8rem;
  margin: 0 -1.5rem;
  @include flexify($wrap: nowrap, $align: center);

  @include Tablet {
    justify-content: space-between;
    overflow: hidden;
    background-color: $white;
    width: 100%;
    border-radius: 7rem;
    margin: 0 0 1rem;
    .indicator {
      position: static;
    }
  }

  &-link, & span {
    @include flexify($align: center, $justify: center);
    cursor: pointer;
    text-decoration: none;
    color: $black;
    background-color: transparent;
    border: none;
    padding: 0.8rem 1.6rem;
    white-space: nowrap;
    font: {
      family: $ff-sb;
      size: 1.6rem;
      weight: $fw-sb;
    }
    line-height: 1.5em;
    border-radius: 10rem;
    transition-property: background-color, color;
    transition-duration: .3s;

    &.active {
      background-color: $blue-alert-bg;
      pointer-events: none;
      color: $black;
    }
  }

  & span {
    color: $grey-txt;
  }
}

.lang-switcher {
  gap: 1rem;
  @include flexify($align: center, $wrap: nowrap);

  & span {
    font: {
      family: $ff-sb;
      size: 1.4rem;
    }
  }

  @include Tablet {
    padding: 0.5rem;
  }
}
