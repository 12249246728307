.footer {
  background-color: $footer-bg;
  color: $grey-txt-footer;
  font-size: 1.6rem;
  width: 100%;
  padding: {
    top: 4rem;
    bottom: 4rem;
  };
  margin-top: auto;
  @include flexify($justify: center);

  &-col {
    flex-grow: 1;
    width: 100%;
    color: $white;

    @include flexify($align: center);

    @include Tablet {
      width: auto;
    }

    span {
      color: $grey-txt-footer;
    }
  }

  &-icon {
    @include flexify($justify: center, $align: center);
    width: 3.5rem;
    height: 3.5rem;
    padding: .8rem;
    background-color: $blue-accent;
    border-radius: 1rem;
    margin-right: 1rem;

    svg {
      width: 100%;
      color: $white;
    }
  }

  a {
    color: $white;
    text-decoration: none;
    border: none;
  }

  &-link {
    cursor: pointer;
    font-size: 1.2rem;
    line-height: 1em;
    border-bottom: 1px solid;

    &:not(:last-child) {
      margin-right: 3rem;
    }
  }
}
