.sp {
  display: block;
  width: 8em;
  height: 8em;
}
.sp__ring {
  stroke: hsla(var(--hue),90%,5%,0.1);
  transition: stroke 0.3s;
}
.sp__worm1,
.sp__worm2,
.sp__worm2-1 {
  animation: worm1 5s ease-in infinite;
}
.sp__worm1 {
  transform-origin: 64px 64px;
}
.sp__worm2,
.sp__worm2-1 {
  transform-origin: 22px 22px;
}
.sp__worm2 {
  animation-name: worm2;
  animation-timing-function: linear;
}
.sp__worm2-1 {
  animation-name: worm2-1;
  stroke-dashoffset: 175.92;
}

/* Dark theme */
@media (prefers-color-scheme: dark) {
  :root {
    --bg: hsl(var(--hue),90%,5%);
    --fg: hsl(var(--hue),90%,95%);
  }
  .sp__ring {
    stroke: transparentize($grey-border, .5);
  }
}

/* Animations */
@keyframes worm1 {
  from,
  to {
    stroke-dashoffset: 0
  }
  12.5% {
    animation-timing-function: ease-out;
    stroke-dashoffset: -175.91;
  }
  25% {
    animation-timing-function: cubic-bezier(0,0,0.43,1);
    stroke-dashoffset: -307.88;
  }
  50% {
    animation-timing-function: ease-in;
    stroke-dashoffset: -483.8;
  }
  62.5% {
    animation-timing-function: ease-out;
    stroke-dashoffset: -307.88;
  }
  75% {
    animation-timing-function: cubic-bezier(0,0,0.43,1);
    stroke-dashoffset: -175.91;
  }
}
@keyframes worm2 {
  from,
  12.5%,
  75%,
  to {
    transform: rotate(0) translate(-42px,0);
  }
  25%,
  62.5% {
    transform: rotate(0.5turn) translate(-42px,0);
  }
}
@keyframes worm2-1 {
  from {
    stroke-dashoffset: 175.91;
    transform: rotate(0);
  }
  12.5% {
    animation-timing-function: cubic-bezier(0,0,0.42,1);
    stroke-dashoffset: 0;
    transform: rotate(0);
  }
  25% {
    animation-timing-function: linear;
    stroke-dashoffset: 0;
    transform: rotate(1.5turn);
  }
  37.5%,
  50% {
    stroke-dashoffset: -175.91;
    transform: rotate(1.5turn);
  }
  62.5% {
    animation-timing-function: cubic-bezier(0,0,0.42,1);
    stroke-dashoffset: 0;
    transform: rotate(1.5turn);
  }
  75% {
    animation-timing-function: linear;
    stroke-dashoffset: 0;
    transform: rotate(0);
  }
  87.5%,
  to {
    stroke-dashoffset: 175.92;
    transform: rotate(0);
  }
}
