.dropdown-card {
  border: {
    radius: 1rem;
    width: 1px;
    style: solid;
    color: $grey-border;
  }
  padding: 1rem;
  background-color: $white;
}

.dropdown {
  perspective: 1000px;
  z-index: 100;
  position: relative;
  @extend .user-active-effect;

  &-header {
    color: $grey-txt;
    width: 100%;
    gap: 1rem;
    @extend .justify-content-between, .align-items-center, .dropdown-card;
  }

  &-menu {
    @extend .dropdown-card, .mt-1;
    box-shadow: 0 0 2rem transparentize($black, .9);
    display: none;
    padding: 0;
    font-size: 1.2rem;

    .menu-item {
      display: none;
    }

    &.expanded {
      display: block;
      perspective: 1000px;
      animation: {
        name: menu-2;
        duration: 300ms;
        fill-mode: forwards;
        timing-function: ease-in-out;        }
    }


      .menu-item {
        transform-origin: top center;
        opacity: 1;
        padding: 1rem;
        display: block;
    }
  }

  &-title {
    color: $black;
    flex-grow: 1;
  }
}

@keyframes appear {
  from {
    transform: translateY(-150px);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes disappearance {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}


@keyframes menu-2 {
  0% {
    opacity: 0;
    transform: rotateX(-90deg);
  }
  50% {
    transform: rotateX(20deg);
  }
  100% {
    opacity: 1;
    transform: rotateX(0deg);
  }
}
