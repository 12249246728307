@use 'sass:map';

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

@for $i from 0 to 9 {
  .mb-#{$i} {
    margin-bottom: calc(#{$i} * 5px) !important;
  }
}

@for $i from 0 to 7 {
  .mt-#{$i} {
    margin-top: calc(#{$i} * 5px) !important;
  }
}

@include Tablet {
  @for $i from 0 to 7 {
    .tablet-mb-#{$i} {
      margin-bottom: calc(#{$i} * 5px) !important;
    }
  }

  @for $i from 0 to 7 {
    .tablet-mt-#{$i} {
      margin-top: calc(#{$i} * 5px) !important;
    }
  }
}

@include Phone {
  @for $i from 0 to 7 {
    .phone-mb-#{$i} {
      margin-bottom: calc(#{$i} * 5px) !important;
    }
  }

  @for $i from 0 to 7 {
    .phone-mt-#{$i} {
      margin-top: calc(#{$i} * 5px) !important;
    }
  }
}

@for $i from 0 to 7 {
  .ml-#{$i} {
    margin-left: calc(#{$i} * 5px) !important;
  }
}

@for $i from 0 to 7 {
  .mr-#{$i} {
    margin-right: calc(#{$i} * 5px) !important;
  }
}

@for $i from 0 to 11 {
  .gap-#{$i} {
    gap: calc(#{$i} * 5px) !important;
  }
}

@for $i from 0 to 6 {
  .pt-#{$i} {
    padding-top: calc(#{$i} * 5px) !important;
  }
}

@for $i from 0 to 6 {
  .pb-#{$i} {
    padding-bottom: calc(#{$i} * 5px) !important;
  }
}

@include SmallTablet {
  .gap-sm-6 {
    gap: 3rem !important;
  }
  .gap-sm-4 {
    gap: 2rem !important;
  }
}

.p-1 {
  padding: 5px !important;
}

.m-auto {
  margin: auto;
}

@for $i from 0 to 101 {
  .mw-#{$i} {
    max-width: #{$i}rem !important;
  }
}

@for $i from 0 to 50 {
  .min-h-#{$i} {
    min-height: #{$i}px !important;
  }
}

.flex {
  @include flexify($wrap: wrap);

  &-nowrap {
    @include flexify($wrap: nowrap);
  }

  &-column {
    @include flexify($column: true);
  }

  &-center {
    @include flexify($align: center, $justify: center);
  }
}

@include SmallTablet {
  .flex-sm-no-wrap {
    flex-wrap: nowrap;
  }
}

@include Tablet {
  .flex-tbl-no-wrap {
    flex-wrap: nowrap;
  }
}

@each $align, $val in $flexAligns {
  .align-items {
    &-#{$align} {
      @include flexify($align: $val);
    }
  }

  .justify-content {
    &-#{$align} {
      @include flexify($justify: $val);
    }
  }
}

.w-100 {
  width: 100%;
}

.w-auto {
  min-width: auto !important;
  width: auto !important;
}

.max-w-54 {
  margin: auto;
  max-width: 54rem;
  width: 100%;
}

.h-100 {
  height: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

.pointer-events-none {
  pointer-events: none;
}

.border {
  &-bottom {
    border-bottom: 1px solid $grey-border;

    &-light {
      border-bottom: 1px solid lighten($grey-border, 14%);
    }

    &-blue {
      border-bottom: 1px solid $blue-alert-border;
    }
  }

  &-top {
    border-top: 1px solid $grey-border;

    &-light {
      border-top: 1px solid lighten($grey-border, 14%);
    }
  }

  &-r {
    border-radius: 50%;
    overflow: hidden;
  }

  &-r-2 {
    border-radius: 2rem !important;
  }
  &-r-0-5 {
    border-radius: 0.5rem;
  }
}

.field-lbl {
  font: {
    family: $ff-sb;
    size: 1.4rem;
    weight: $fw-sb;
  }
  color: $grey-txt;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
}

.select-menu {
  background-color: $white;
  border-radius: 1rem;
  border: 1px solid $grey-border;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.select-menu-option {
  padding: 0.7rem 1.5rem;
  cursor: pointer;
  @extend .field-lbl;
  color: $black;

  &:hover {
    color: $black;
    background-color: $blue-alert-bg;
  }
}

.with-important-banks {
  [class$='option'] {
    &:nth-child(-n + 6) {
      color: $primary;
      background-color: $grey-light-bg;

      &:hover {
        background-color: tint($primary, 80%);
      }
    }
  }
}

.default-css {
  b,
  strong {
    font: {
      family: $ff-b;
      weight: $fw-b;
    }
  }

  p {
    margin: {
      top: 1rem;
      bottom: 1rem;
    }
  }

  ol {
    list-style: {
      type: decimal;
      position: inside;
    }
  }

  ul {
    list-style: {
      type: circle;
      position: inside;
    }
  }
}

.lined-card-row {
  @include flexify($justify: space-between, $align: center);
  padding: {
    top: 1.5rem;
  }
  @extend .border-top-light;

  .address {
    @include flexify($justify: flex-start);
    margin-top: 0.5rem;
    text-align: left;
  }
}

.personal-information {
  & > *:nth-child(2) {
    border: none;
    padding: 0;
  }
}

.prolongation {
  .lined-card-row {
    &:not(:first-child) {
      padding: 0;
      border: none;
    }
  }
}

.active-loan-grid {
  display: grid;
  gap: 3rem;
  grid-template-rows: repeat(3, 1fr);
  width: 100%;
  @include Tablet {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
}

.field-helper {
  position: relative;
  width: 100%;
  max-width: 100%;

  // ** CHILDREN **
  &:not([class*='checkbox']) {
    [class*='__label'] {
      position: absolute;
      z-index: 0;
      left: 1rem;
      top: 1.4rem;
      transition: all 0.3s;
      padding: 0 0.5rem;

      @extend .field-lbl;
    }
  }

  [class*='__element_left'],
  [class*='__element_right'] {
    border-radius: 0.5rem;
    padding: 0.5rem;
    background-color: $grey-main;
    min-width: 3.8rem;
    @extend .field-lbl;
    color: $black;
    flex-shrink: 0;
    @include flexify($align: center, $justify: center);
  }

  [class*='__element_right'] {
    background-color: transparent;
    color: $black;
    font: {
      family: $ff-b;
      weight: $fw-b;
    }
    svg {
      width: 2rem;
    }
  }

  [class*='__element_left'] {
    margin-left: 1rem;
  }

  [class$='-control'],
  [class*='__input-wrapper'] {
    border-radius: 1rem;
    border: 1px solid $grey-border;
    background-color: transparent;
    padding: 0;
    position: relative;
    min-height: 4.5rem;
    max-height: 4.7rem;
    @include flexify($align: center);

    [class*='-ValueContainer'],
    [class*='-hlgwow'] {
      margin: 0;
      padding: 1.4rem 0 1.4rem 1.4rem;
      @extend .field-lbl;
      z-index: 5;
      position: relative;

      @include flexify($align: center, $justify: flex-start, $column: false);

      [class*='-singleValue'] {
        grid-area: unset;
        margin: 0;
      }

      input {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transform: none;
      }
    }
  }

  [class$='-control'] {
    cursor: pointer;
  }

  [class*='__input-wrapper'] {
    padding: 0;
  }

  input {
    width: auto;
    min-width: 10rem;
    border: none;
    padding: 1.3rem 1.5rem;
    flex-grow: 1;
    caret-color: $primary;
    background-color: transparent;
    color: $black;
    font: {
      family: $ff-sb;
      weight: $fw-sb;
      size: 1.4rem;
    }
    line-height: 1.358em;

    &[type='checkbox'],
    &[type='radio'] {
      width: auto;
      height: 0;
    }

    &[type='number']::-webkit-outer-spin-button,
    &[type='number']::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    &::placeholder {
      color: $grey-txt;
      font: {
        family: $ff-sb;
        weight: $fw-sb;
        size: 1.4rem;
      }
    }

    &:autofill,
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-background-clip: text;
      border-radius: 10rem;
      color: $black;
      font: {
        family: $ff-base;
        weight: $fw-r;
        size: 1.6rem;
      }
    }
  }
  // ** EMPTY FIELD **
  &:not([class*='__no-empty']) {
    &[class*='__prefix'] {
      [class*='__label'] {
        left: 5.8rem;
      }
    }
  }

  &[class*='__active'],
  &[class*='__no-empty'] {
    [class*='__label'] {
      top: -0.5rem;
      left: 1.2rem;
      z-index: 1;
      background-color: $white;
      color: $grey-txt;
      font: {
        family: $ff-base;
        weight: $fw-r;
        size: 1rem;
      }
    }

    input {
      &::placeholder {
        color: $grey-txt;
      }
    }

    &:focus,
    &:hover {
      [class*='__label'] {
        color: $primary;
      }
    }
  }

  &[class*='__active'] {
    [class*='__input-wrapper'] {
      border-color: $primary;
    }
  }

  // ** INVALID FIELD **
  &:not([class*='__invalid']) {
    [class$='-control'],
    [class*='__input-wrapper'] {
      border-color: $grey-border;
      box-shadow: none;

      &:hover {
        border-color: $primary;
      }

      &:focus {
        input {
          &::placeholder {
            color: $grey-txt;
          }
        }
      }
    }

    [class$='-control'] {
      cursor: pointer;
    }

    &.invalidity-indicator {
      [class*='_element_right'] {
        display: none;
      }
    }
  }

  &[class*='__invalid'] {
    @extend .invalid-field-helper;
  }
}

.invalid-field-helper {
  .error-text {
    display: inline-block;
    margin-top: 0.1rem;
    font-size: 1rem;
    line-height: 1.2em;
  }

  [class*='__input-wrapper'],
  [class$='-control'],
  &[class*='__active'],
  [class*='__label'],
  [class*='__element'],
  [class*='-indicatorContainer'] {
    color: map.get($error-field, 'color');
    border-color: map.get($error-field, 'border');

    &,
    &:focus,
    &:active {
      @extend .shake;
    }
  }

  [class*='__input-wrapper'],
  [class$='-control'],
  &[class*='__active'],
  [class*='-indicatorContainer'] {
    background-color: transparent;
  }

  input {
    color: map.get($error-field, 'color');
  }

  &.invalidity-indicator {
    [class*='_element_right'] {
      @include flexify();
      background-color: transparent;
    }
  }

  &:hover {
    [class*='-control'],
    [class*='__input-wrapper'] {
      border-color: map.get($error-field, 'border');
    }
  }
}

.invalid-card-helper {
  border-color: $error;
  background-color: $red-light;
  position: relative;
  overflow: hidden;

  .photo-upload-error {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem;
    gap: 2rem;
    font-weight: $fw-sb;
    @include flexify($align: center, $justify: center, $column: true);
  }

  &:active,
  &:hover {
    transform: none;
    box-shadow: 0 0 0 2px lighten($error, 40%);
  }
}

.pill-helper {
  font: {
    family: $ff-sb;
    weight: $fw-sb;
  }
  white-space: nowrap;
  text-decoration: none;
  font-size: 1.4rem;
  line-height: 1.8rem;
  padding: 0.8rem 1rem;
  border-radius: 10rem;
  color: $black;
  gap: 0.3rem;

  @include flexify($align: center, $justify: center, $wrap: nowrap);
}

.pill-default {
  &-icon {
    @extend .pill-default;
    padding: 0.6rem 1rem;
  }
}

.pill-ellipsed-content {
  display: grid;
  grid-template-columns: 1fr auto;
}

.alert-helper {
  width: 100%;
  padding: 1rem;
  border: {
    radius: 1rem;
  }
  font-size: 1.4rem;
  color: $black;
  gap: 1rem;
  background-color: transparent;

  @include flexify($align: flex-start);
  //@extend .user-active-effect;

  .pill-default,
  .btn {
    padding: 0.6rem 1.5rem;
    min-width: auto;
    text-decoration: none;
    border-radius: 10rem;
    margin: 0 3.5rem;

    &,
    .btn-text {
      font-size: 1.2rem;
    }
  }

  .input-box {
    &,
    &[class*='invalid'] {
      min-width: auto;
      max-width: 55%;

      [class*='input-wrapper'] {
        background-color: $white;
        padding: 0.6rem 1rem;
        min-height: auto;

        input {
          width: 100%;
          padding: 0;
          @include Phone {
            font-size: 1.2rem;
          }
        }
      }
    }
  }

  @include Tablet {
    .btn {
      &,
      .btn-text {
        font-size: 1.6rem;
      }
    }
  }
}

.border-b {
  border-bottom: 1px solid $grey-hover;
}

.border-n {
  border: none;
}

.opacity-0 {
  opacity: 0;
}

.icon-card {
  width: 2.5rem;
  height: 2.5rem;
  padding: 0.4rem;
  border-radius: 0.7rem;
  @include flexify($align: center, $justify: center);
}

.icon-wrap {
  width: 3.5rem;
  height: 3.5rem;
  padding: 0.8rem;
  border-radius: 1rem;
  background-color: $blue-alert-bg;

  @include flexify($align: center, $justify: center);
}

.timer-block {
  width: 100%;
  height: 5rem;
  font-size: 2rem;
  line-height: 1.3em;
  border-radius: 1rem;
  font: {
    family: $ff-b;
    weight: $fw-b;
  }
  color: $black;
  background: {
    color: $grey-main;
  }
  margin-bottom: 0.7rem;
  @include flexify($align: center, $justify: center);
}

.instruction-pic {
  padding-top: 8rem;
  flex-grow: 1;
  background: {
    size: contain;
    repeat: no-repeat;
    position: center;
  }
}

.info-item {
  padding: 0.8rem 1.2rem;
  font: {
    family: $ff-sb;
    size: 1.2rem;
    weight: $fw-sb;
  }
  border-radius: 1rem;
  background: {
    color: $grey-main;
  }

  &.error {
    color: $red;
    background: {
      color: $red-light;
    }
  }

  &.crossed {
    background: {
      color: transparent;
    }
    text-decoration: line-through !important;
    color: $secondary;
  }
}

.loan-grid {
  display: grid;
  gap: 2rem;
  margin-bottom: 4rem;
  grid-template-areas:
    'one'
    'two'
    'three';

  @include SmallTablet {
    grid-template-areas:
      'one two'
      'three two';
    grid-template-columns: 1fr 1fr;
    gap: 3rem;
    margin: 0;
  }

  .one {
    grid-area: one;
  }

  .two {
    grid-area: two;
  }

  .three {
    grid-area: three;
  }
}

.promo-code {
  background-color: $grey-main;
  border-radius: 1rem;
  padding: 0.9rem 1rem;

  &__wrapper {
    @include flexify($align: flex-start, $justify: center);
    margin-top: 1.5rem;
    width: 100%;
    gap: 1rem;

    .input-box {
      width: 57%;

      &__input-wrapper {
        min-height: 3rem;
        background-color: $white;
        width: 100%;

        input {
          font: {
            family: $ff-sb;
            size: 1.2rem;
          }
          padding: 0.7rem 1.2rem;

          &::placeholder {
            font-size: 1.2rem;
          }
        }
      }
    }

    button {
      width: 43%;
      min-width: auto;
      padding: 0.6rem;
    }
  }
}

.account-number {
  &__title {
    @include Phone {
      font-size: 1.3rem;
    }
  }
}

.confirm-phone {
  .input-checkbox {
    &__label {
      @include Phone {
        font: {
          size: 1.2rem;
        }
      }
    }
  }
}

#wt-omni-widget {
  z-index: 1500 !important;
}

.offer {
  &-block {
    padding: 4rem 5rem;
    background-color: $white;
    width: calc(100% + 3rem);
    border-radius: 2rem 2rem 0 0;

    @include SmallTablet {
      border-radius: 2rem;
    }
    @include Phone {
      padding: 3rem 1.5rem 4rem;
    }
  }

  &-wrap {
    background-color: $green-light;
    border-radius: 1rem;
    padding: 1rem 1.5rem 0 0.5rem;
  }
}

.one {
  & section {
    margin: 0 0 2rem !important;

    @include SmallTablet {
      margin: 0 0 3rem !important;
    }
  }
  & .other-section {
    width: 100%;
    max-width: 100%;
  }
}

.other-section {
  @extend .request-status-section;
  padding: 2.5rem 1.5rem !important;
  max-width: 100% !important;
  width: 100%;

  & .icon {
    max-width: 17.5rem;
    width: 100% !important;

    & svg {
      max-width: 7.2rem;
      min-width: 7.2rem;
    }

    @include SmallPhone {
      justify-content: flex-start;
      max-width: 7.2rem;
    }
  }

  & .btn {
    min-width: auto;
    width: 100%;
  }
}

.partner-modal {
  & .icon {
    max-width: 14.5rem;
    width: 100% !important;

    & svg {
      max-width: 7.2rem;
      min-width: 7.2rem;
    }

    @include SmallPhone {
      justify-content: flex-start;
      max-width: 7.2rem;
    }
  }

  & .btn {
    min-width: auto;
    width: 100%;
  }
}

.webview {
  &-animation {
    margin: auto;
    max-width: 17rem;
    width: 100%;
  }

  &-card {
    border-radius: 0.5rem;
    padding: 1.5rem;
    background-color: $grey-light-bg;
  }
}
.input-otp {
  max-width: 6.5rem;
  min-width: 4.5rem;
  height: 4.5rem;
  padding: 1rem;
  text-align: center;
  font-size: 2rem;
  font-weight: 600;
  color: $grey-txt;
  background-color: $grey-button;
  border: 1px solid $grey-button;
  border-radius: 0.5rem;
  transition: all 0.3s ease;

  &.error {
    border-color: $red;
    background-color: $white;
  }

  &:focus {
    background-color: $white;
    border-color: $blue;
    color: $black;
  }
}

.achievements {
  &-item {
    max-width: 17rem;
    width: 100%;

    @include Phone {
      max-width: 100%;
    }
  }

  @include Phone {
    flex-direction: column;
  }
}

.loader-small {
  @include flexify($align: center, $justify: center);
  width: 2rem;
  height: 2rem;
  & .loader-wrap {
    padding: 0.25rem;
  }
}
