.select-box {
  @extend .field-helper;

  [class$='-menu'] {
    @extend .select-menu;
    z-index: 10000;
  }

  [class$='MenuList'] {
    padding: 0.8rem 0;
  }

  [class$='option'] {
    @extend .select-menu-option;
  }

  [class$='-ValueContainer'] {
    padding: 0;
    @extend .field-lbl;
    z-index: 5;
  }

  [class$='-ValueContainer2'] {
    padding-right: 0 !important;
  }

  [class$='-indicatorSeparator'] {
    display: none;
  }

  [class*='-indicatorContainer'] {
    z-index: 10;
    position: absolute;
    inset: 0;

    svg {
      display: none;
    }
  }

  [class$='-Input'] {
    padding: 0;
    margin: 0;
  }

  [class*='-singleValue'] {
    font: {
      family: $ff-sb;
      weight: $fw-sb;
    }
  }

  [class*='-placeholder'] {
    color: $grey-txt;
    font: {
      family: $ff-sb;
      weight: $fw-sb;
    }
  }

  [class*='_open'] {
    color: $grey-txt;
    font: {
      family: $ff-sb;
      weight: $fw-sb;
    }
  }

  & svg {
    position: absolute;
    top: 1.9rem;
    right: 1.3rem;
  }

  &[class*='__open'] {
    & svg {
      transform: scale(-1);
    }
  }
}
