@keyframes rotation-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes shake-animation {
  0% {
    transform: translateX(.3rem);
  }

  25% {
    transform: translateX(-.3rem);
  }

  50% {
    transform: translateX(.1rem);
  }

  75% {
    transform: translateX(-.1rem);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes waving {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.rotate {
  display: inline-block;
  animation: {
    name: rotation-animation;
    duration: 2s;
    timing-function: linear;
    delay: 0s;
    iteration-count: infinite;
    fill-mode: forwards;
  }
}

.shake {
  animation: {
    name: shake-animation;
    duration: .4s;
    timing-function: ease-out;
    delay: 0s;
    iteration-count: 1;
    fill-mode: forwards;
  }
}

.user-active-effect {
  transition: .3s;
  &:active {
    transform: translateY(1px);
  }
}

.user-control-effect {
  transition: .3s;
  &:active {
    transform: scaleX(.96)
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: .3;
  }
  100% {
    opacity: 1;
  }
}

.blinking {
  animation: blink 1s infinite;
}
