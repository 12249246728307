.input-box__slider {
  max-width: 12rem;
  padding-bottom: 0;

  .input-box__input-wrapper {
    margin-top: 0;
    padding: 0;
    min-height: 2.7rem;

    @include flexify($justify: space-between);

    input {
      min-width: 0;
      flex-grow: 0;
      padding: .5rem 0 .5rem 1rem;
      width: auto;
      max-width: calc(100% - 4rem);
      border-radius: .5rem;
      color: $black;
      font: {
        family: $ff-b;
        weight: $fw-b;
      }
    }
  }
}

.slider {
  gap: 1rem;
  @include flexify($column: true, $align: stretch);

  &-content {
    @include flexify($justify: center, $align: center);
  }

  &-wrapper {
    @include flexify($justify: space-between, $align: center);
    margin-bottom: .6rem;

    p {
      color: $grey-txt;
      @extend .justify-content-between, .txt-base;
    }
  }

  &-track {
    position: relative !important;
    flex-grow: 1;
    max-width: 100%;
    background-color: $blue-alert-bg;
    height: .7rem;
    border-radius: 10rem;
    padding: 0 1.5rem;

    &__range {
      position: relative;
      height: 100%;

      &::before, &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        width: 3rem;
        border-radius: 10rem;
        z-index: -1;
      }

      &::before {
        left: -1.5rem;
        background: rgba(42,70,147,1);
      }

      &::after {
        right: -1.5rem;
        background: $blue-alert-bg;

      }
    }
  }

  &-thumb {
    @include flexify($justify: center, $align: center);
    outline: none;

    &:focus, &:active {
      outline: none;
    }

    &__content {
      position: relative;
      border-radius: 2.5rem;
      background: linear-gradient(185deg, rgba(137,177,237,1) 0%, rgba(42,70,147,1) 70%);
      width: 3rem;
      height: 2rem;

      &::before, &::after {
        content: '';
        position: absolute;
        top: .5rem;
        height: 1rem;
        width: .2rem;
        background-color: $white;
        border-radius: 1rem;
      }
      &::before {
        left: 1.2rem;
      }
      &::after {
        right: 1.2rem;
      }
    }
  }

  &-btn {
    flex-grow: 0;
    width: 3rem;
  }

  &-footer {
    color: $grey-txt;
    @extend .justify-content-between, .txt-base;
  }

  form.slider-header, .slider-header {
    @include flexify($column: false, $align: flex-start, $justify: space-between, $wrap: nowrap);

    .slider-header__title {
      font-size: 1.4rem;
      @extend .txt-sb;
    }
  }

  &-content {
    @include flexify($align: center);

    svg {
      display: none;
    }
  }
}
