.col-pads {
  padding: {
    left: $grid-padding;
    right: $grid-padding;
  }
}

.container {
  width: 100%;
  max-width: $container-width;
  padding: {
    left: $grid-padding;
    right: $grid-padding;
    bottom: 0;
  }
}

.row {
  @include flexify($wrap: wrap);
  width: calc(100% + ($grid-padding * 2));
  margin: {
    left: -#{$grid-padding};
    right: -#{$grid-padding};
  }
}

.col-6 {
  flex: 1 1 50%;
  max-width: 50%;
  @extend .col-pads;
}

.col-12 {
  flex: 1 1 100%;
  max-width: 100%;
  @extend .col-pads;
}

.col-sm-6 {
  @extend .col-pads;
  @include SmallTablet {
    flex: 1 1 calc(100% / 12 * 6);
    max-width: calc(100% / 12 * 6);
  }
}

.col-md-6 {
  @extend .col-pads;
  @include Tablet {
    flex: 1 1 calc(100% / 12 * 6);
    max-width: calc(100% / 12 * 6);
  }
}

.col-md-5 {
  @extend .col-pads;
  @include Tablet {
    flex: 1 1 calc(100% / 12 * 5);
    max-width: calc(100% / 12 * 5);
  }
}

.col-md-3 {
  @extend .col-pads;
  @include Tablet {
    flex: 1 1 calc(100% / 12 * 3);
    max-width: calc(100% / 12 * 3);
  }
}

.hidden {
  display: none !important;

  &-xs {
    @media (max-width: calc(#{$bp-xs} - 1px)) {
      display: none !important;
    }
  }

  &-sm {
    @media (min-width: $bp-xs) and (max-width: calc(#{$bp-sm} - 1px)) {
      display: none !important;
    }
  }

  &-md {
    @media (min-width: $bp-sm) and (max-width: calc(#{$bp-md} - 1px)) {
      display: none !important;
    }
  }

  &-lg {
    @media (min-width: $bp-md) and (max-width: calc(#{$bp-lg} - 1px)) {
      display: none !important;
    }
  }

  &-desktop {
    @media (min-width: $bp-lg) {
      display: none !important;
    }
  }

  &-min-lg-tablet {
    @media (min-width: $bp-md) {
      display: none !important;
    }
  }

  &-sm-tablet {
    @media (min-width: $bp-sm) {
      display: none !important;
    }
  }

  &-lg-tablet {
    @media (max-width: calc(#{$bp-md} - 1px)) {
      display: none !important;
    }
  }

  &-tablet {
    @media (max-width: calc(#{$bp-sm} - 1px)) {
      display: none !important;
    }
  }

  &-mobile {
    @media (max-width: calc(#{$bp-lg} - 1px)) {
      display: none !important;
    }
  }
}

